import React, { useContext, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom';
import LoadingScrean from '../Loading/LoadingScrean';
import { appContext } from '../../Context/appContextProvider';
import { Helmet } from 'react-helmet';

export default function Category_Products({lang}) {
    const {id} = useParams();
    const { getOneCategory , OneCategory} = useContext(appContext);


    const memo = useMemo(()=>{
        getOneCategory(id , lang);
    },[id])
  return (
    <>
      {!OneCategory ? (
        <LoadingScrean />
      ) : (
        <>
          <Helmet>
            <title>{OneCategory.category.name}</title>
          </Helmet>
          <div id="specificCategory" className="py-5">
            <h2 className="text-center">{OneCategory.category.name}</h2>
            <div className="container">
              <div className="w-100 mb-5 categoryText">
                {/* <figure className='mb-0 w-50 m-auto rounded-3 overflow-hidden'>
                                <img loading='lazy' src={OneCategory.category.image.secure_url} alt={OneCategory.category.name} className='w-100' />
                            </figure> */}
                {OneCategory.category.name == "التمور" ? (
                  <div className="text-end">
                    <h3 className="mb-3">
                      {" "}
                      :نحن نعمل في توريد و تجارة مجموعة واسعة من التمور الأكثر
                      شهرة في المملكة العربية السعودية{" "}
                    </h3>
                    <h5 className="mb-2 me-5">تمر السكرى القصيم<i className="bi ms-3 bi-skip-backward-fill"></i></h5>
                    <h5 className="mb-2 me-5">تمر عجوة المدينة<i className="bi ms-3 bi-skip-backward-fill"></i></h5>
                    <h5 className="mb-2 me-5">تمر المجدول<i className="bi ms-3 bi-skip-backward-fill"></i></h5>
                    <h5 className="mb-2 me-5">تمر السجعى<i className="bi ms-3 bi-skip-backward-fill"></i></h5>
                    <h5 className="mb-2 me-5">تمر خضرى<i className="bi ms-3 bi-skip-backward-fill"></i></h5>
                    <h5 className="mb-2 me-5">تمر المبروم<i className="bi ms-3 bi-skip-backward-fill"></i></h5>
                  </div>
                ) : OneCategory.category.name == "المكسرات" ? (
                  <div className="text-end">
                    <h3 className="mb-3">
                      {" "}
                      :نحن نقوم بتوريد كثير من أصناف المسكرات حول العالم
                    </h3>
                    <h5 className="mb-2 me-5">الكاجو<i className="bi ms-3 bi-skip-backward-fill"></i></h5>
                    <h5 className="mb-2 me-5">الفسدق<i className="bi ms-3 bi-skip-backward-fill"></i></h5>
                    <h5 className="mb-2 me-5">البندق<i className="bi ms-3 bi-skip-backward-fill"></i></h5>
                    <h5 className="mb-2 me-5">اللوز<i className="bi ms-3 bi-skip-backward-fill"></i></h5>
                    <h5 className="mb-2 me-5">عين الجمل<i className="bi ms-3 bi-skip-backward-fill"></i></h5>
                    <h5 className="mb-2 me-5">الفول السودانى<i className="bi ms-3 bi-skip-backward-fill"></i></h5>
                  </div>
                ) : OneCategory.category.name == "القهوة" ? (
                  <div className="text-end">
                    <h3 className="mb-3">
                      {" "}
                      :نحن نقوم بتوريد كثير من أصناف حبوب القهوة الروبوستا و
                      الارابيكا حول العالم
                    </h3>
                    <h5 className="mb-2 me-5">حبوب قهوة البرازيل<i className="bi ms-3 bi-skip-backward-fill"></i></h5>
                    <h5 className="mb-2 me-5">حبوب قهوة كولومبيا<i className="bi ms-3 bi-skip-backward-fill"></i></h5>
                    <h5 className="mb-2 me-5">حبوب قهوة اندونسيا<i className="bi ms-3 bi-skip-backward-fill"></i></h5>
                    <h5 className="mb-2 me-5">حبوب قهوة فيتنام<i className="bi ms-3 bi-skip-backward-fill"></i></h5>
                    <h5 className="mb-2 me-5">حبوب قهوة الهند<i className="bi ms-3 bi-skip-backward-fill"></i></h5>
                    <h5 className="mb-2 me-5">حبوب قهوة إثيوبيا<i className="bi ms-3 bi-skip-backward-fill"></i></h5>
                  </div>
                ) : OneCategory.category.name == "الحبوب و البقوليات" ? (
                  <div className="text-end">
                    <h3 className="mb-3">
                      {" "}
                      :نحن نقوم بتوريد كثير من أصناف الحبوب و البقوليات حول
                      العالم
                    </h3>
                    <h5 className="mb-2 me-5">الفول كامل الحبة<i className="bi ms-3 bi-skip-backward-fill"></i></h5>
                    <h5 className="mb-2 me-5">الفول المجروش<i className="bi ms-3 bi-skip-backward-fill"></i></h5>
                    <h5 className="mb-2 me-5">الحمص<i className="bi ms-3 bi-skip-backward-fill"></i></h5>
                    <h5 className="mb-2 me-5">الفاصوليا البيضا<i className="bi ms-3 bi-skip-backward-fill"></i></h5>
                    <h5 className="mb-2 me-5">اللوبيا<i className="bi ms-3 bi-skip-backward-fill"></i></h5>
                    <h5 className="mb-2 me-5">العدس<i className="bi ms-3 bi-skip-backward-fill"></i></h5>
                  </div>
                ) : OneCategory.category.name == "Dates" ? (
                  <div className="text-start">
                    <h3 className="mb-3">
                      We are sourcing and supplying wide range of Most
                      well-Known Dates of Kingdom of Saudi Arabia Dates:
                    </h3>
                    <h5 className="mb-2 ms-5"><i className="bi me-3 bi-fast-forward-fill"></i>Sukkari Al-Qassim dates</h5>
                    <h5 className="mb-2 ms-5"><i className="bi me-3 bi-fast-forward-fill"></i>Ajwa Medina dates</h5>
                    <h5 className="mb-2 ms-5"><i className="bi me-3 bi-fast-forward-fill"></i>Magdool dates</h5>
                    <h5 className="mb-2 ms-5"><i className="bi me-3 bi-fast-forward-fill"></i>Sagai dates</h5>
                    <h5 className="mb-2 ms-5"><i className="bi me-3 bi-fast-forward-fill"></i>Khadrawy dates</h5>
                    <h5 className="mb-2 ms-5"><i className="bi me-3 bi-fast-forward-fill"></i>Mabroom dates</h5>
                  </div>
                ) : OneCategory.category.name == "Nuts" ? (
                  <div className="text-start">
                    <h3 className="mb-3">
                      We are souring and suppling many kinds of nuts worldwide:{" "}
                    </h3>
                    <h5 className="mb-2 ms-5"><i className="bi me-3 bi-fast-forward-fill"></i>Cashew</h5>
                    <h5 className="mb-2 ms-5"><i className="bi me-3 bi-fast-forward-fill"></i>Pistachio</h5>
                    <h5 className="mb-2 ms-5"><i className="bi me-3 bi-fast-forward-fill"></i>Hazelnut</h5>
                    <h5 className="mb-2 ms-5"><i className="bi me-3 bi-fast-forward-fill"></i>Almonds</h5>
                    <h5 className="mb-2 ms-5"><i className="bi me-3 bi-fast-forward-fill"></i>Walnuts Kernel</h5>
                    <h5 className="mb-2 ms-5"><i className="bi me-3 bi-fast-forward-fill"></i>Peanut Kernel</h5>
                  </div>
                ) : OneCategory.category.name == "Coffees" ? (
                  <div className="text-start">
                    <h3 className="mb-3">
                      We are souring and suppling many kinds of coffees beans
                      Robusta And Arabica beans worldwide:{" "}
                    </h3>
                    <h5 className="mb-2 ms-5"><i className="bi me-3 bi-fast-forward-fill"></i>Brazil Coffee Beans</h5>
                    <h5 className="mb-2 ms-5"><i className="bi me-3 bi-fast-forward-fill"></i>Colombia Coffee Beans</h5>
                    <h5 className="mb-2 ms-5"><i className="bi me-3 bi-fast-forward-fill"></i>Ethiopia Coffee Beans </h5>
                    <h5 className="mb-2 ms-5"><i className="bi me-3 bi-fast-forward-fill"></i>India Coffee Beans</h5>
                    <h5 className="mb-2 ms-5"><i className="bi me-3 bi-fast-forward-fill"></i>Indonesia Coffee Beans</h5>
                    <h5 className="mb-2 ms-5"><i className="bi me-3 bi-fast-forward-fill"></i>Vietnam Coffee Beans</h5>
                  </div>
                ) : (
                  <div className="text-start">
                    {console.log(OneCategory.category.name)}
                    <h3 className="mb-3">
                    We are sourcing and suppling many thanks of Grains and pulses
                    wideworld range:{" "}
                    </h3>
                    <h5 className="mb-2 ms-5"><i className="bi me-3 bi-fast-forward-fill"></i>Whole Fava Beans</h5>
                    <h5 className="mb-2 ms-5"><i className="bi me-3 bi-fast-forward-fill"></i>Split Fava Beans</h5>
                    <h5 className="mb-2 ms-5"><i className="bi me-3 bi-fast-forward-fill"></i>Chickpeas</h5>
                    <h5 className="mb-2 ms-5"><i className="bi me-3 bi-fast-forward-fill"></i>White Kidney Beans</h5>
                    <h5 className="mb-2 ms-5"><i className="bi me-3 bi-fast-forward-fill"></i>Black-eyed beans</h5>
                    <h5 className="mb-2 ms-5"><i className="bi me-3 bi-fast-forward-fill"></i>Lentils</h5>
                  </div>
                )}
              </div>
              <div className="text-success text-center mb-4">
                {lang == "en" ? (
                  <h2>{OneCategory.category.name} Products</h2>
                ) : (
                  <h2>منتجات {OneCategory.category.name}</h2>
                )}
              </div>
              <div className="row gy-3 justify-content-center">
                {OneCategory.category.Products.map((pro) => (
                    <div key={pro._id} className="col-3 categoryProductsSize">
                    <figure className="mb-0 p-3 bg-white containerBox rounded-3 shadow-lg">
                        <div className="overflow-hidden position-relative">
                        <img
                            src={pro.images[0].secure_url}
                            className="w-100 rounded-3"
                            alt={pro.name}
                        />
                        </div>
                        <figcaption className="p-2 text-center">
                        <h3 className='w-75 m-auto p-2 shadow-lg rounded-3'>{pro.name}</h3>
                        </figcaption>
                    </figure>
                    </div>
                ))}
              </div>
              {/* <div className="w-100 mb-5">
                            <figure className='mb-0 w-50 m-auto rounded-3 overflow-hidden'>
                                <img loading='lazy' src={OneCategory.category.image.secure_url} alt={OneCategory.category.name} className='w-100' />
                            </figure>
                        </div> */}
            </div>
          </div>
        </>
      )}
    </>
  );
}
