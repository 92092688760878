import React from 'react';
import { Link } from 'react-router-dom';
import LoadingScrean from '../../Loading/LoadingScrean';
export default function OurGellary({lang , Categories}) {
  return (
    <>
      <section id="OurGallery" className="py-5">
        <h2 className="text-center text-success mb-5">
          {lang == "en" ? "Product’s Categories" : "اقسام المنتجات"}
        </h2>
        <div className="container">
          <div className="row gy-4 justify-content-center">
            {Categories ? (
              Categories.ctegories.map((category) => (
                <div key={category._id} className="col-3">
                  <figure className="mb-0 p-3 bg-white rounded-3 containerBox shadow-lg">
                    <div className="overflow-hidden position-relative">
                      <img
                        src={category.image.secure_url}
                        className="w-100 rounded-3"
                        alt={category.name}
                      />
                    </div>
                    <figcaption className="p-2 text-center">
                      <h3 className='mt-3'>
                        <Link
                          to={`/category/${category._id}`}
                          className="link-success shadow-lg p-2 rounded-3 text-truncate"
                        >
                          {category.name}
                        </Link>
                      </h3>
                    </figcaption>
                  </figure>
                </div>
              ))
            ) : (<>
              <div className="col-3">
                <div className="p-3">
                  <div className="card" aria-hidden="true">
                    <div className="card-body">
                      <h5 className="card-title placeholder-glow text-center">
                        <span className="placeholder col-6"></span>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="p-3">
                  <div className="card" aria-hidden="true">
                    <div className="card-body">
                      <h5 className="card-title placeholder-glow text-center">
                        <span className="placeholder col-6"></span>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="p-3">
                  <div className="card" aria-hidden="true">
                    <div className="card-body">
                      <h5 className="card-title placeholder-glow text-center">
                        <span className="placeholder col-6"></span>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="p-3">
                  <div className="card" aria-hidden="true">
                    <div className="card-body">
                      <h5 className="card-title placeholder-glow text-center">
                        <span className="placeholder col-6"></span>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
}
